// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_footer__eGCBv {
  width: 100%;
  border-top: 1px solid var(--gray-light7);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 12px;
  padding-bottom: 16px;
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  line-height: 15.4px;
  text-align: left;
  color: var(--gray);
  white-space: nowrap;
  margin-top: 16px;
}
@media screen and (max-width: 1250px) {
  .Footer_footer__eGCBv {
    flex-direction: column;
    justify-content: center;
    gap: 12px;
  }
}
@media screen and (max-width: 768px) {
  .Footer_footer__eGCBv {
    border-top: none;
  }
}
.Footer_footer__navigation__2IqBP {
  gap: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .Footer_footer__navigation__2IqBP {
    flex-direction: column;
    display: none;
  }
}
.Footer_footer__navigation_item__s9ZhC {
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/pages/Welcome/components/Footer/Footer.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,wCAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,iBAAA;EACA,oBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;AACF;AAAE;EAhBF;IAiBI,sBAAA;IACA,uBAAA;IACA,SAAA;EAGF;AACF;AAFE;EArBF;IAsBI,gBAAA;EAKF;AACF;AAJE;EACE,SAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AAMJ;AALI;EALF;IAMI,sBAAA;IACA,aAAA;EAQJ;AACF;AAPI;EACE,mBAAA;AASN","sourcesContent":[".footer {\n  width: 100%;\n  border-top: 1px solid var(--gray-light7);\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding-top: 12px;\n  padding-bottom: 16px;\n  font-family: Inter;\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 15.4px;\n  text-align: left;\n  color: var(--gray);\n  white-space: nowrap;\n  margin-top: 16px;\n  @media screen and (max-width: 1250px) {\n    flex-direction: column;\n    justify-content: center;\n    gap: 12px;\n  }\n  @media screen and (max-width: 768px) {\n    border-top: none;\n  }\n  &__navigation {\n    gap: 12px;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    @media screen and (max-width: 768px) {\n      flex-direction: column;\n      display: none;\n    }\n    &_item {\n      white-space: nowrap;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `Footer_footer__eGCBv`,
	"footer__navigation": `Footer_footer__navigation__2IqBP`,
	"footer__navigation_item": `Footer_footer__navigation_item__s9ZhC`
};
export default ___CSS_LOADER_EXPORT___;
