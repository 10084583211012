// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loader_loaderContainer__yFzuF {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Loader_loaderContainer__yFzuF img {
  width: 392px;
  height: 112px;
}

@media (max-width: 640px) {
  .Loader_loaderContainer__yFzuF img {
    width: 265px;
    height: 80px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Loader/Loader.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AACA;EACE,YAAA;EACA,aAAA;AAEF;;AAAA;EACE;IACE,YAAA;IACA,YAAA;EAGF;AACF","sourcesContent":[".loaderContainer {\n  position: fixed;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.loaderContainer img {\n  width: 392px;\n  height: 112px;\n}\n@media (max-width: 640px) {\n  .loaderContainer img {\n    width: 265px;\n    height: 80px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loaderContainer": `Loader_loaderContainer__yFzuF`
};
export default ___CSS_LOADER_EXPORT___;
