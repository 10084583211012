import useWindowWidth from "hooks/useWindowWidth";
import styles from "./Videos.module.scss";
import ReactDOM from "react-dom";
import { Sheet } from "react-modal-sheet";
import React, { useState } from "react";
import { Close } from "assets/images";
interface IProps {
    video: {
        id: string;
        img: string;
        header: string;
        type: string[];
        text?: undefined;
    }
}
const VideoItem = ({ video }: IProps) => {
    const windowWidth = useWindowWidth();
    const [isOpened, setIsOpened] = useState(false);


    return (
        <>
            <div className={styles.learn_list_item} key={video.id} onClick={() => setIsOpened(true)}>
                <img className={styles.learn_list_item_img} src={video.img} alt={"Slider1"} />
                <div className={styles.learn_list_item_title}>{video.header}</div>
                <div className={styles.learn_list_item_text}>{video.type.map((type, index) => (
                    <React.Fragment key={index}>
                        {index > 0 && <div className={styles.learn_dot}></div>}
                        {type}
                    </React.Fragment>
                ))}</div>
            </div>

            {isOpened && windowWidth > 768 && (
                ReactDOM.createPortal(
                    <div
                        className={styles.inspirationVideoPopUp}
                        onClick={() => setIsOpened(false)}
                    >
                        <div
                            className={styles.inspirationVideoPopUp_content}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className={styles.inspirationVideoPopUp_closeButton}>
                                <img
                                    onClick={() => setIsOpened(false)}
                                    className={styles.nR_tips_close}
                                    src={Close}
                                    alt="Close"
                                />
                            </div>
                            <div className={styles.learn_list_item_title2}>{video.header}</div>
                            <img className={styles.inspirationVideoPopUp_content_img} src={video.img} />
                            <div className={styles.learn_list_item_text2}>{video.type.map((type, index) => (
                                <React.Fragment key={index}>
                                    {index > 0 && <div className={styles.learn_dot}></div>}
                                    {type}
                                </React.Fragment>
                            ))}</div>
                        </div>

                    </div>,
                    document.body,
                )
            )
            }
            {isOpened && windowWidth < 768 &&
                (
                    <Sheet
                        isOpen={isOpened}
                        onClose={() => setIsOpened(false)}
                        dragVelocityThreshold={500}
                        initialSnap={1}
                        detent="full-height"
                        style={{
                            backdropFilter: "blur(3px)",
                            WebkitBackdropFilter: "blur(3px)",
                            background: "#11100E99",
                        }}
                        className={styles.learnMore_sheetMain}
                    >
                        <div
                            className={styles.learnMore_closeArea}
                            onClick={() => setIsOpened(false)}
                        ></div>
                        <Sheet.Container className={styles.learnMore_sheetInspiration}>
                            <Sheet.Content className={styles.learnMore_sheetContainer}>
                                <div className={styles.learnMore_container_line}></div>
                                <Sheet.Scroller draggableAt="both">
                                    <div className={styles.learnMore_container_line}></div>
                                    <div className={styles.learn_list_item_title}>{video.header}</div>
                                    <div className={styles.learn_list_item_text2}>{video.type.map((type, index) => (
                                        <React.Fragment key={index}>
                                            {index > 0 && <div className={styles.learn_dot}></div>}
                                            {type}
                                        </React.Fragment>
                                    ))}</div>
                                    <img className={styles.inspirationVideoPopUp_content_img} src={video.img} />
                                </Sheet.Scroller>

                            </Sheet.Content>
                        </Sheet.Container>
                    </Sheet>
                )}
        </>

    );


}

export default VideoItem