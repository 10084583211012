// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  height: 100%;
}
body {
  margin: 0;
  height: 100%;
}
html, body {
  overflow-x: hidden;
}

#root {
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;AACA;EACE,SAAS;EACT,YAAY;AACd;AACA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,sBAAsB;EACtB,SAAS;EACT,UAAU;AACZ","sourcesContent":["html {\n  height: 100%;\n}\nbody {\n  margin: 0;\n  height: 100%;\n}\nhtml, body {\n  overflow-x: hidden;\n}\n\n#root {\n  height: 100%;\n  overflow: scroll;\n  overflow-x: hidden;\n}\n* {\n  box-sizing: border-box;\n  margin: 0;\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
