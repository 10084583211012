import { QUESTIONS_ON_LOCATION } from "consts/consts";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  selectRequestInfo,
  updateInterviewInfoSettings,
} from "../../../../../redux/requests/reducer";
import styles from "../../../NewRequest.module.scss";

const QuestionsOnLocation = () => {
  const interviewSettings = useSelector(selectRequestInfo)?.interviewSettings;
  const locationSettings = interviewSettings?.questionSettings.locationSettings;
  const name = locationSettings?.name;
  const phone = locationSettings?.phone;
  const email = locationSettings?.email;

  const containerRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const handleFullNameUpdate = (name: string) => {
    locationSettings && dispatch(updateInterviewInfoSettings({
      interviewInfoSettings: {
        ...interviewSettings,
        questionSettings: {
          ...interviewSettings.questionSettings,
          type: QUESTIONS_ON_LOCATION,
          locationSettings: {
            ...interviewSettings.questionSettings.locationSettings,
            name: name,

          }
        }
      },
      isEdit: true
    }))
  }
  const handlePhoneUpdate = (phone: string) => {
    locationSettings && dispatch(updateInterviewInfoSettings({
      interviewInfoSettings: {
        ...interviewSettings,
        questionSettings: {
          ...interviewSettings.questionSettings,
          type: QUESTIONS_ON_LOCATION,
          locationSettings: {
            ...interviewSettings.questionSettings.locationSettings,
            phone: Number(phone),

          }
        }
      },
      isEdit: true
    }))
  }

  const handleEmailUpdate = (email: string) => {
    locationSettings && dispatch(updateInterviewInfoSettings({
      interviewInfoSettings: {
        ...interviewSettings,
        questionSettings: {
          ...interviewSettings.questionSettings,
          type: QUESTIONS_ON_LOCATION,
          locationSettings: {
            ...interviewSettings.questionSettings.locationSettings,
            email: email,

          }
        }
      },
      isEdit: true
    }))
  }


  return (
    <div
      ref={containerRef}
      className={`
      ${styles.box}
      ${styles.box_submit}
      ${styles.box_xl}
      ${styles.box_expanded}
  `}
    >
      <div className={`${styles.box_container} ${styles.box_containerSubmit} `}>
        {" "}
        <div className={styles.box_title2}>Interviewer</div>
        <div className={styles.box_title2} style={{ whiteSpace: "unset" }}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut.
        </div>
        <div className={styles.box_companyContainer}>
          <div className={styles.box_companyContainer_text}>Full name</div>
          <input
            value={name}
            onChange={(e) => {
              handleFullNameUpdate(e.target.value)
            }}
            placeholder="Full name"
            type="company"
            className={`
            ${styles.box_companyContainer_input} 
            `}
          />
        </div>
        <div className={styles.box_inputsContainer}>
          <div className={styles.box_addressContainer}>
            <div className={styles.box_addressContainer_text}>Phone</div>
            <input
              className={`
                    ${styles.box_addressContainer_input} 
                    `}
              value={phone}
              onChange={(e) => {
                handlePhoneUpdate(e.target.value)
              }}
              placeholder="+1 123 456 7890"
              name="city"
              type="number"
            />
          </div>
          <div className={styles.box_addressContainer}>
            <div className={styles.box_addressContainer_text}>Email</div>
            <input
              className={`
                ${styles.box_addressContainer_input} 
                `}
              value={email}
              onChange={(e) => {
                handleEmailUpdate(e.target.value)
              }}
              placeholder="example@email.com"
              name="state"
              type="text"
            />
          </div>

        </div>
      </div>
    </div>
  );
};

export default QuestionsOnLocation;
