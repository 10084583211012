import { CheckBox, CheckBoxSelected, CloseRed, LastDays } from "assets/images";
import { CURRENT_MONTH_RANGE, CURRENT_YEAR_RANGE, DEFAULT, LAST_12_MONTHS, LAST_30_DAYS, LAST_WEEK_RANGE } from "consts/consts";
import useWindowWidth from "hooks/useWindowWidth";
import { useState } from "react";
import { Sheet } from "react-modal-sheet";
import { TRange } from "types/types";
import { getDatesInRange } from "utils/dateRange";

import styles from "../ProjectsPage.module.scss";

interface IProps {
    dateRange: TRange;
    setDateRange: React.Dispatch<React.SetStateAction<TRange>>;
}

const DateFilter = ({ dateRange, setDateRange }: IProps) => {
    const [isOpened, setIsOpened] = useState(false);
    const width = useWindowWidth();
    const handleOpen = () => {
        setIsOpened(true)
    }
    const handleBlur = () => {
        width > 990 && setIsOpened(false);
    }
    const handleMobileBlur = () => {
        setIsOpened(false)
    }
    const handleDateDelete = (e: React.MouseEvent<HTMLImageElement>) => {
        e.stopPropagation();
        setIsOpened(false);
        setDateRange(DEFAULT);
    }
    const handleCloseArea = (e: React.MouseEvent) => {
        e.stopPropagation();
        setIsOpened(false)
    }
    const handleDateSelect = (range: TRange, e: React.MouseEvent) => {
        e.stopPropagation();
        setIsOpened(false);
        setDateRange(range);
    }
    return (
        <div className={styles.lastDays}
            tabIndex={0}
            onClick={handleOpen}
            onBlur={handleBlur}>
            <img src={LastDays} alt="filter" /> {dateRange !== DEFAULT ? dateRange : 'Date range'}
            {dateRange !== DEFAULT && <img src={CloseRed} alt="filter" onClick={(e) => { handleDateDelete(e) }} />}
            {width > 990 && isOpened && <div className={styles.lastDays_container}>
                <div className={styles.lastDays_container_item}
                    onClick={() => setDateRange(LAST_WEEK_RANGE)}>
                    <img src={dateRange === LAST_WEEK_RANGE ? CheckBoxSelected : CheckBox} alt="filter" />
                    <div className={styles.lastDays_container_item_info}>
                        <div className={styles.lastDays_container_item_text}>Last week </div>
                        <div className={styles.lastDays_container_item_title}>
                            {getDatesInRange(LAST_WEEK_RANGE).startDate} -
                            {getDatesInRange(LAST_WEEK_RANGE).endDate}</div>
                    </div>
                </div>
                <div className={styles.lastDays_container_item}
                    onClick={() => setDateRange(CURRENT_MONTH_RANGE)}>
                    <img src={dateRange === CURRENT_MONTH_RANGE ? CheckBoxSelected : CheckBox} alt="filter" />
                    <div className={styles.lastDays_container_item_info}>
                        <div className={styles.lastDays_container_item_text}>Current month</div>
                        <div className={styles.lastDays_container_item_title}>
                            {getDatesInRange(CURRENT_MONTH_RANGE).startDate} -
                            {getDatesInRange(CURRENT_MONTH_RANGE).endDate}</div>
                    </div>
                </div>
                <div className={styles.lastDays_container_item}
                    onClick={() => setDateRange(LAST_30_DAYS)} >
                    <img src={dateRange === LAST_30_DAYS ? CheckBoxSelected : CheckBox} alt="filter" />
                    <div className={styles.lastDays_container_item_info}>
                        <div className={styles.lastDays_container_item_text}>Last 30 days </div>
                        <div className={styles.lastDays_container_item_title}>
                            {getDatesInRange(LAST_30_DAYS).startDate} - {getDatesInRange(LAST_30_DAYS).endDate}</div>
                    </div>
                </div>
                <div className={styles.lastDays_container_item}
                    onClick={() => setDateRange(CURRENT_YEAR_RANGE)}>
                    <img src={dateRange === CURRENT_YEAR_RANGE ? CheckBoxSelected : CheckBox} alt="filter" />
                    <div className={styles.lastDays_container_item_info}>
                        <div className={styles.lastDays_container_item_text}>Current year </div>
                        <div className={styles.lastDays_container_item_title}>
                            {getDatesInRange(CURRENT_YEAR_RANGE).startDate} - {getDatesInRange(CURRENT_YEAR_RANGE).endDate}</div>
                    </div>
                </div>
                <div className={styles.lastDays_container_item} onClick={() => setDateRange(LAST_12_MONTHS)}>
                    <img src={dateRange === LAST_12_MONTHS ? CheckBoxSelected : CheckBox} alt="filter" />
                    <div className={styles.lastDays_container_item_info}>
                        <div className={styles.lastDays_container_item_text}>Last 12 months </div>
                        <div className={styles.lastDays_container_item_title}>{getDatesInRange(LAST_12_MONTHS).startDate} - {getDatesInRange(LAST_12_MONTHS).endDate}</div>
                    </div>
                </div>

            </div>}
            {width < 990 && isOpened && (
                <Sheet
                    isOpen={isOpened}
                    onClose={handleMobileBlur}
                    className={styles.dateRange_sheetMain}
                    detent="full-height"
                >
                    <div
                        className={styles.dateRange_closeArea2}
                        onClick={(e) => handleCloseArea(e)}
                    ></div>
                    <Sheet.Container className={styles.dateRange_sheet2}>
                        <Sheet.Content >
                            <div className={styles.dateRange_line}></div>
                            <div className={styles.dateRange_container}>
                                <div className={styles.lastDays_container_item} onClick={(e) => handleDateSelect(LAST_WEEK_RANGE, e)}>
                                    <img src={dateRange === LAST_WEEK_RANGE ? CheckBoxSelected : CheckBox} alt="filter" />
                                    <div className={styles.lastDays_container_item_info}>
                                        <div className={styles.lastDays_container_item_text}>Last week </div>
                                        <div className={styles.lastDays_container_item_title}>
                                            {getDatesInRange(LAST_WEEK_RANGE).startDate}
                                            - {getDatesInRange(LAST_WEEK_RANGE).endDate}</div>
                                    </div>
                                </div>
                                <div className={styles.lastDays_container_item} onClick={(e) => handleDateSelect(CURRENT_MONTH_RANGE, e)}>
                                    <img src={dateRange === CURRENT_MONTH_RANGE ? CheckBoxSelected : CheckBox} alt="filter" />
                                    <div className={styles.lastDays_container_item_info}>
                                        <div className={styles.lastDays_container_item_text}>Current month</div>
                                        <div className={styles.lastDays_container_item_title}>
                                            {getDatesInRange(CURRENT_MONTH_RANGE).startDate}
                                            - {getDatesInRange(CURRENT_MONTH_RANGE).endDate}</div>
                                    </div>
                                </div>
                                <div className={styles.lastDays_container_item} onClick={(e) => handleDateSelect(LAST_30_DAYS, e)} >
                                    <img src={dateRange === LAST_30_DAYS ? CheckBoxSelected : CheckBox} alt="filter" />
                                    <div className={styles.lastDays_container_item_info}>
                                        <div className={styles.lastDays_container_item_text}>Last 30 days </div>
                                        <div className={styles.lastDays_container_item_title}>
                                            {getDatesInRange(LAST_30_DAYS).startDate}
                                            - {getDatesInRange(LAST_30_DAYS).endDate}</div>
                                    </div>
                                </div>
                                <div className={styles.lastDays_container_item} onClick={(e) => handleDateSelect(CURRENT_YEAR_RANGE, e)}>
                                    <img src={dateRange === CURRENT_YEAR_RANGE ? CheckBoxSelected : CheckBox} alt="filter" />
                                    <div className={styles.lastDays_container_item_info}>
                                        <div className={styles.lastDays_container_item_text}>Current year </div>
                                        <div className={styles.lastDays_container_item_title}>
                                            {getDatesInRange(CURRENT_YEAR_RANGE).startDate}
                                            - {getDatesInRange(CURRENT_YEAR_RANGE).endDate}</div>
                                    </div>
                                </div>
                                <div className={styles.lastDays_container_item} onClick={(e) => handleDateSelect(LAST_12_MONTHS, e)}>
                                    <img src={dateRange === LAST_12_MONTHS ? CheckBoxSelected : CheckBox} alt="filter" />
                                    <div className={styles.lastDays_container_item_info}>
                                        <div className={styles.lastDays_container_item_text}>Last 12 months </div>
                                        <div className={styles.lastDays_container_item_title}>
                                            {getDatesInRange(LAST_12_MONTHS).startDate}
                                            - {getDatesInRange(LAST_12_MONTHS).endDate}</div>
                                    </div>
                                </div>

                            </div>



                        </Sheet.Content>
                    </Sheet.Container>
                </Sheet>
            )}
        </div>
    );
};

export default DateFilter;