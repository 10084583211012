import styles from "./Inspirations.module.scss";
import { useState } from "react";
import useWindowWidth from "hooks/useWindowWidth";
import { inspirationsList } from "pages/data";
import InspirationItem from "./InspirationItem";
import InspirationVideos from "./Videos";
const InspirationsPage = () => {
    const [filter, setFilter] = useState('All Options');
    const width = useWindowWidth();
    return (
        <>

            <div className={styles.page_header}>
                <div className={styles.page_header_title}>
                    <div>Inspiration</div>
                    <div className={styles.page_header_title_text}>A magna ut pulvinar est vulputate nec enim imperdiet. Placerat cras.</div>
                </div>

            </div>
            <InspirationVideos />
            <div className={styles.page_types}>
                <div className={`${styles.page_types_item} ${filter === 'All Options' ? styles.page_types_item_active : ''}`} onClick={() => setFilter('All Options')} >{width > 768 ? 'All' : 'All'}</div>
                <div className={`${styles.page_types_item} ${filter === 'Most Requested' ? styles.page_types_item_active : ''}`} onClick={() => setFilter('Most Requested')}>{width > 768 ? 'Most Requested' : 'Most Requested'}</div>
                <div className={`${styles.page_types_item} ${filter === 'Trending' ? styles.page_types_item_active : ''}`} onClick={() => setFilter('Trending')}>{width > 768 ? 'Trending' : 'Trending'}</div>
            </div>
            <div className={styles.page_list}>
                {
                    inspirationsList.filter(item => filter === 'All Options' ?
                        true : filter === 'Most Requested' ? item.mostRequested : item.trending)
                        .map((item) => {
                            return (
                                <InspirationItem key={item.id} item={item} />
                            )
                        })
                }
            </div>
        </>

    )
}

export default InspirationsPage;