import { useState } from "react";
import styles from "./Inspirations.module.scss";
import ReactDOM from "react-dom";
import { Sheet } from "react-modal-sheet";
import useWindowWidth from "hooks/useWindowWidth";
import { Close } from "assets/images";
import { embedCode1, embedCode2, embedCode3, embedCode4, IInspiration } from "pages/data";

const InspirationItem = ({ item }: { item: IInspiration }) => {
    const [isOpened, setIsOpened] = useState(false);
    const width = useWindowWidth();
    const [selectedVideo, setSelectedVideo] = useState(embedCode1);
    return (
        <>
            <div key={item.id} className={styles.page_list_item}>

                <img src={item.img} alt="" />
                <div className={styles.page_list_item_header}>{item.header}</div>
                <div className={styles.page_list_item_text}>{item.text}</div>
                <div className={styles.page_list_item_container}>
                    <div className={styles.page_list_item_button} onClick={() => setIsOpened(true)} >See details</div>
                </div>
            </div>
            {isOpened && width > 768 && (
                ReactDOM.createPortal(
                    <div
                        className={styles.inspirationPopUpContainer}
                        onClick={() => setIsOpened(false)}
                    >
                        <div
                            className={styles.inspirationPopUpContainer_content}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className={styles.inspirationPopUpContainer_content_header}>
                                <img src={item.img} alt={"AnnouncementVideos"} />
                                <div className={styles.welcomeContainer_closeButton}>
                                    <img
                                        onClick={() => setIsOpened(false)}
                                        className={styles.nR_tips_close}
                                        src={Close}
                                        alt="Close"
                                    />
                                </div>
                            </div>
                            <div className={styles.inspirationPopUpContainer_content_title}>
                                {item.header2}
                            </div>
                            <div className={styles.inspirationPopUpContainer_content_text}>
                                {item.text2}
                            </div>
                            <div className={styles.inspirationPopUpContainer_content_text}>
                                {item.text3}
                            </div>
                            {item.title1 && (
                                <div className={styles.inspirationPopUpContainer_content_title2}>
                                    {item.title1}
                                </div>
                            )
                            }
                            {
                                item.title2 && (
                                    <div className={styles.inspirationPopUpContainer_content_title2}>
                                        {item.title2}
                                    </div>
                                )
                            }
                            <div
                                style={{ width: "100%", height: "auto", maxWidth: "480px", paddingTop: "20px", margin: "0 auto" }}
                                dangerouslySetInnerHTML={{ __html: selectedVideo }}
                            />
                            <div className={styles.inspirationPopUpContainer_swiper}>
                                <div className={styles.inspirationPopUpContainer_swiper_item}
                                    onClick={() => {
                                        setSelectedVideo(embedCode1);
                                    }}
                                >
                                    <div className={styles.inspirationPopUpContainer_swiper_item_shadow}></div>
                                    <div
                                        style={{ width: "100%", height: "auto", maxWidth: "480px", margin: "0 auto" }}
                                        dangerouslySetInnerHTML={{ __html: embedCode1 }}
                                    />
                                </div>

                                <div className={styles.inspirationPopUpContainer_swiper_item}
                                    onClick={() => {
                                        setSelectedVideo(embedCode2);
                                    }}
                                >
                                    <div className={styles.inspirationPopUpContainer_swiper_item_shadow}></div>
                                    <div
                                        style={{ width: "100%", height: "auto", maxWidth: "480px", margin: "0 auto" }}
                                        dangerouslySetInnerHTML={{ __html: embedCode2 }}
                                    />
                                </div>
                                <div className={styles.inspirationPopUpContainer_swiper_item}
                                    onClick={() => {
                                        setSelectedVideo(embedCode3);
                                    }}
                                >
                                    <div className={styles.inspirationPopUpContainer_swiper_item_shadow}></div>
                                    <div
                                        style={{ width: "100%", height: "auto", maxWidth: "480px", margin: "0 auto" }}
                                        dangerouslySetInnerHTML={{ __html: embedCode3 }}
                                    />
                                </div>
                                <div className={styles.inspirationPopUpContainer_swiper_item}
                                    onClick={(e) => {
                                        setSelectedVideo(embedCode4);
                                        e.stopPropagation();

                                    }}
                                >
                                    <div className={styles.inspirationPopUpContainer_swiper_item_shadow}></div>
                                    <div
                                        style={{ width: "100%", height: "auto", maxWidth: "480px", margin: "0 auto", userSelect: "none" }}
                                        dangerouslySetInnerHTML={{ __html: embedCode4 }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>,
                    document.body,
                )
            )
            }
            {isOpened && width < 768 &&
                (
                    <Sheet
                        isOpen={isOpened}
                        onClose={() => setIsOpened(false)}
                        dragVelocityThreshold={500}
                        initialSnap={1}
                        detent="full-height"
                        style={{
                            backdropFilter: "blur(3px)",
                            WebkitBackdropFilter: "blur(3px)",
                            background: "#11100E99",
                        }}
                        className={styles.learnMore_sheetMain}
                    >
                        <div
                            className={styles.learnMore_closeArea}
                            onClick={() => setIsOpened(false)}
                        ></div>
                        <Sheet.Container className={styles.learnMore_sheetInspiration}>
                            <Sheet.Content className={styles.learnMore_sheetContainer}>
                                <div className={styles.learnMore_container_line}></div>
                                <Sheet.Scroller draggableAt="both">
                                    <div className={styles.learnMore_container_line}></div>
                                    <div className={styles.inspirationPopUpContainer_content_title}>
                                        {item.header2}
                                    </div>
                                    <div className={styles.inspirationPopUpContainer_content_text}>
                                        {item.text2}
                                    </div>
                                    <div className={styles.inspirationPopUpContainer_content_text}>
                                        {item.text3}
                                    </div>
                                    {item.title1 && (
                                        <div className={styles.inspirationPopUpContainer_content_title2}>
                                            {item.title1}
                                        </div>
                                    )
                                    }
                                    {
                                        item.title2 && (
                                            <div className={styles.inspirationPopUpContainer_content_title2}>
                                                {item.title2}
                                            </div>
                                        )
                                    }
                                    <div
                                        style={{ width: "100%", height: "auto", maxWidth: "480px", margin: "0 auto", marginTop: "20px", }}
                                        dangerouslySetInnerHTML={{ __html: selectedVideo }}
                                    />
                                    <div className={styles.inspirationPopUpContainer_swiper}>
                                        <div className={styles.inspirationPopUpContainer_swiper_item}
                                            onClick={() => {
                                                setSelectedVideo(embedCode1);
                                            }}
                                        >
                                            <div className={styles.inspirationPopUpContainer_swiper_item_shadow}></div>
                                            <div
                                                style={{ width: "100%", height: "auto", maxWidth: "480px", margin: "0 auto" }}
                                                dangerouslySetInnerHTML={{ __html: embedCode1 }}
                                            />
                                        </div>

                                        <div className={styles.inspirationPopUpContainer_swiper_item}
                                            onClick={() => {
                                                setSelectedVideo(embedCode2);
                                            }}
                                        >
                                            <div className={styles.inspirationPopUpContainer_swiper_item_shadow}></div>
                                            <div
                                                style={{ width: "100%", height: "auto", maxWidth: "480px", margin: "0 auto" }}
                                                dangerouslySetInnerHTML={{ __html: embedCode2 }}
                                            />
                                        </div>
                                        <div className={styles.inspirationPopUpContainer_swiper_item}
                                            onClick={() => {
                                                setSelectedVideo(embedCode3);
                                            }}
                                        >
                                            <div className={styles.inspirationPopUpContainer_swiper_item_shadow}></div>
                                            <div
                                                style={{ width: "100%", height: "auto", maxWidth: "480px", margin: "0 auto" }}
                                                dangerouslySetInnerHTML={{ __html: embedCode3 }}
                                            />
                                        </div>
                                        <div className={styles.inspirationPopUpContainer_swiper_item}
                                            onClick={(e) => {
                                                setSelectedVideo(embedCode4);
                                                e.stopPropagation();

                                            }}
                                        >
                                            <div className={styles.inspirationPopUpContainer_swiper_item_shadow}></div>
                                            <div
                                                style={{ width: "100%", height: "auto", maxWidth: "480px", margin: "0 auto", userSelect: "none" }}
                                                dangerouslySetInnerHTML={{ __html: embedCode4 }}
                                            />
                                        </div>
                                    </div>
                                </Sheet.Scroller>

                            </Sheet.Content>
                        </Sheet.Container>
                    </Sheet>
                )}
        </>

    )
}


export default InspirationItem;

