import {
  Arrow2,
  SettingsDots,
} from "assets/images";
import { Link } from "react-router-dom";
import styles from "./ChatSupport.module.scss";
import ChatraWidget from "components/Chatra/Chatra";

const ChatSupport = (): JSX.Element => {


  return (
    <div className={styles.supportContainer}>
      <div className={styles.supportContainer__content_main_header}>
        <div className={styles.supportContainer__content_main_header_text}>
          Chat with Support
        </div>
        <div className={styles.supportContainer__content_main_header_subText}>
          <div
            className={
              styles.supportContainer__content_main_header_subText_dot
            }
          ></div>{" "}
          Choose preferred method <img src={Arrow2} alt={"Arrow"} />{" "}
          <span>Chat with LimeLite</span>
        </div>
        <div className={styles.supportContainer__content_main_header_mobText}>
          <Link to="/support">
            <div
              className={
                styles.supportContainer__content_main_header_mobText_button
              }
            >
              <img src={Arrow2} alt={"CloseIcon"} />
            </div>
          </Link>
          <div>Chat Support</div>
          <div></div>
        </div>
      </div>
      <div id="chatra-container" className={styles.chatraPage}>
        <ChatraWidget />
      </div>
    </div>
  );
};
export default ChatSupport;
