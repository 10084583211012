import { IVideo } from "./Videos";
import useWindowWidth from "hooks/useWindowWidth";
import styles from "./Inspirations.module.scss";
import ReactDOM from "react-dom";
import { Sheet } from "react-modal-sheet";
import { useState } from "react";
import { Close } from "assets/images";
interface IProps {
    video: IVideo
}
const VideoItem = ({ video }: IProps) => {
    const windowWidth = useWindowWidth();
    const [isOpened, setIsOpened] = useState(false);


    return (
        <div
            style={{ backgroundImage: `url(${video.img})` }}
            className={
                styles.videos_cardItem
            } onClick={() => setIsOpened(true)}>
            <div
                className={
                    styles.videos_cardItem_header
                }

            >
                <img src={video.imgType} alt="ere" />{" "}
                {windowWidth > 768 && video.header}
            </div>
            <div
                className={
                    styles.videos_cardItem_text
                }
            >
                {video.text}
            </div>
            {isOpened && windowWidth > 768 && (
                ReactDOM.createPortal(
                    <div
                        className={styles.inspirationVideoPopUp}
                        onClick={() => setIsOpened(false)}
                    >
                        <div
                            className={styles.inspirationVideoPopUp_content}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className={styles.inspirationVideoPopUp_closeButton}>
                                <img
                                    onClick={() => setIsOpened(false)}
                                    className={styles.nR_tips_close}
                                    src={Close}
                                    alt="Close"
                                />
                            </div>
                            <div className={styles.inspirationVideoPopUp_content_header}>
                                {video.text}
                            </div>
                            <img className={styles.inspirationVideoPopUp_content_img} src={video.img} />


                            {/* <div
                                style={{ background: '#11100E80' }}

                            >
                                <img src={video.imgType} alt="ere" />{" "}
                                {video.header}
                            </div> */}

                        </div>

                    </div>,
                    document.body,
                )
            )
            }
            {isOpened && windowWidth < 768 &&
                (
                    <Sheet
                        isOpen={isOpened}
                        onClose={() => setIsOpened(false)}
                        dragVelocityThreshold={500}
                        initialSnap={1}
                        detent="full-height"
                        style={{
                            backdropFilter: "blur(3px)",
                            WebkitBackdropFilter: "blur(3px)",
                            background: "#11100E99",
                        }}
                        className={styles.learnMore_sheetMain}
                    >
                        <div
                            className={styles.learnMore_closeArea}
                            onClick={() => setIsOpened(false)}
                        ></div>
                        <Sheet.Container className={styles.learnMore_sheetInspiration}>
                            <Sheet.Content className={styles.learnMore_sheetContainer}>
                                <div className={styles.learnMore_container_line}></div>
                                <Sheet.Scroller draggableAt="both">
                                    <div className={styles.learnMore_container_line}></div>

                                    <div className={styles.inspirationVideoPopUp_content_header}>
                                        {video.text}
                                    </div>
                                    <img className={styles.inspirationVideoPopUp_content_img} src={video.img} />
                                </Sheet.Scroller>

                            </Sheet.Content>
                        </Sheet.Container>
                    </Sheet>
                )}
        </div>
    );

}

export default VideoItem