// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  height: 100%;
}
body {
  margin: 0;
  height: 100%;
}
html,
body {
  overflow-x: hidden;
}


#root {
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;
}
@media print {
  body {
    overflow-x: unset;
  }
  #root {
    overflow: unset;
    overflow-x: unset;
  }
  #pageContainer {
    overflow: unset;
    overflow-x: unset;
  }
  #PageHeader{
    display: none;
  }
  a{
    display: none;
  }
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
a{
  text-decoration: none;
}

a:hover{
  text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;AACA;EACE,SAAS;EACT,YAAY;AACd;AACA;;EAEE,kBAAkB;AACpB;;;AAGA;EACE,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE;IACE,iBAAiB;EACnB;EACA;IACE,eAAe;IACf,iBAAiB;EACnB;EACA;IACE,eAAe;IACf,iBAAiB;EACnB;EACA;IACE,aAAa;EACf;EACA;IACE,aAAa;EACf;AACF;AACA;EACE,sBAAsB;EACtB,SAAS;EACT,UAAU;AACZ;AACA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":["html {\n  height: 100%;\n}\nbody {\n  margin: 0;\n  height: 100%;\n}\nhtml,\nbody {\n  overflow-x: hidden;\n}\n\n\n#root {\n  height: 100%;\n  overflow: scroll;\n  overflow-x: hidden;\n}\n@media print {\n  body {\n    overflow-x: unset;\n  }\n  #root {\n    overflow: unset;\n    overflow-x: unset;\n  }\n  #pageContainer {\n    overflow: unset;\n    overflow-x: unset;\n  }\n  #PageHeader{\n    display: none;\n  }\n  a{\n    display: none;\n  }\n}\n* {\n  box-sizing: border-box;\n  margin: 0;\n  padding: 0;\n}\na{\n  text-decoration: none;\n}\n\na:hover{\n  text-decoration: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
