import { DirectionArrow, People } from "assets/images";

import FaqsItem from "./components/FaqsItem";
import { data } from "./data";
import styles from "./FAQs.module.scss";
import { Link } from "react-router-dom";

const FAQs = () => {
  return (
    <>
      <div className={styles.lC}>
        <div className={styles.lC_header}>FAQs</div>
        <div className={styles.lC_title} >A magna ut pulvinar est vulputate nec enim imperdiet. Placerat cras.</div>
        <>
          {data.map((item, index) => (
            <FaqsItem
              firstItem={index === 0}
              lastItem={index === data.length - 1}
              item={item}
            />
          ))}


          <div className={styles.lC_contact} >
            <img src={People} alt="" />
            <div className={styles.lC_contact_info} >
              <div className={styles.lC_contact_info_title}>Still have questions?<br></br> We're here to help!</div>
              <Link to="/support" >
                <div className={styles.lC_contact_info_text}> Chat with a specialist
                  <img src={DirectionArrow} alt={"DirectionArrow"} />
                </div>
              </Link>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default FAQs;
