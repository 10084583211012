import { CloseCalendar, EmptyStar, FilledStar, SuccessGap } from "assets/images";
import useWindowWidth from "hooks/useWindowWidth";
import { IRequest } from "interfaces/interfaces";
import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Sheet } from "react-modal-sheet";
import { useDispatch } from "react-redux";
import { Rating } from 'react-simple-star-rating'
import { updateRatingStatus } from "../../redux/requests/reducer";
import Realistic from "react-canvas-confetti/dist/presets/realistic";

import styles from "./ProjectOverview.module.scss";
interface IProps {
    project: IRequest,
    close: () => void,
    handleCheckReview: () => void
}

const ProjectRating = ({ project, close, handleCheckReview }: IProps) => {
    const width = useWindowWidth();
    const dispatch = useDispatch();
    const [currentRatings, setCurrentRatings] = useState(project?.ratingsSettings);
    const [isSubmitted, setIsSubmitted] = useState<boolean | undefined>(undefined);


    const handleSubmit = () => {
        dispatch(updateRatingStatus({
            id: project.id,
            settings: currentRatings
        }));
    }
    const content = <>
        <div className={styles.ratingContainer_header} onClick={
            (e) => {
                e.stopPropagation();
            }
        }>
            How is your request?
        </div>
        <div className={styles.ratingContainer_item}>1. How satisfied are you with the final outcome of your project?
            <Rating
                emptyIcon={<img src={EmptyStar} alt={"empty star"} />}
                fillIcon={<img src={FilledStar} alt={"empty star"} />}
                transition
                allowFraction
                className={styles.rating}
                initialValue={currentRatings.ratings[0].question1}
                onClick={(e) => {
                    setCurrentRatings({
                        ...currentRatings,
                        ratings: currentRatings.ratings.map((rating, index) =>
                            index === 0 ? { ...rating, question1: e, date: new Date() } : rating
                        )
                    });
                }}
            />
        </div>
        <div className={styles.ratingContainer_item}>2. Was the shooting process well-organized and efficient?
            <Rating
                emptyIcon={<img src={EmptyStar} alt={"empty star"} />}
                fillIcon={<img src={FilledStar} alt={"empty star"} />}
                transition
                allowFraction
                className={styles.rating}
                initialValue={currentRatings.ratings[0].question2}
                onClick={(e) => {
                    setCurrentRatings({
                        ...currentRatings,
                        ratings: currentRatings.ratings.map((rating, index) =>
                            index === 0 ? { ...rating, question2: e, date: new Date() } : rating
                        )
                    });
                }}


            />
        </div>
        <div className={styles.ratingContainer_item}>3. How would you rate the communication and collaboration throughout the project?
            <Rating
                emptyIcon={<img src={EmptyStar} alt={"empty star"} />}
                fillIcon={<img src={FilledStar} alt={"empty star"} />}
                transition
                allowFraction
                className={styles.rating}
                initialValue={currentRatings.ratings[0].question3}
                onClick={(e) => {
                    setCurrentRatings({
                        ...currentRatings,
                        ratings: currentRatings.ratings.map((rating, index) =>
                            index === 0 ? { ...rating, question3: e, date: new Date() } : rating
                        )
                    });
                }}

            />
        </div>
        <div className={styles.ratingContainer_item}>4. Did the editing reflect your brand’s vision and requirements?
            <Rating
                emptyIcon={<img src={EmptyStar} alt={"empty star"} />}
                fillIcon={<img src={FilledStar} alt={"empty star"} />}
                transition
                allowFraction
                className={styles.rating}
                initialValue={currentRatings.ratings[0].question4}
                onClick={(e) => {
                    setCurrentRatings({
                        ...currentRatings,
                        ratings: currentRatings.ratings.map((rating, index) =>
                            index === 0 ? { ...rating, question4: e, date: new Date() } : rating
                        )
                    });
                }}

            />
        </div>
        <div className={styles.ratingContainer_item}>5. How likely are you to recommend our services to others?
            <Rating
                emptyIcon={<img src={EmptyStar} alt={"empty star"} />}
                fillIcon={<img src={FilledStar} alt={"empty star"} />}
                transition
                allowFraction
                className={styles.rating}
                initialValue={currentRatings.ratings[0].question5}
                onClick={(e) => {
                    setCurrentRatings({
                        ...currentRatings,
                        ratings: currentRatings.ratings.map((rating, index) =>
                            index === 0 ? { ...rating, question5: e, date: new Date() } : rating
                        )
                    });
                }}
            />
        </div>
        <div className={styles.ratingContainer_title}>Additional Comments</div>
        <input className={styles.ratingContainer_input} type={'text'} placeholder="Type review..."
            value={currentRatings.ratings[0].comment}
            onChange={(e) => {
                setCurrentRatings({
                    ...currentRatings,
                    ratings: currentRatings.ratings.map((rating, index) =>
                        index === 0 ? { ...rating, comment: e.target.value, date: new Date() } : rating
                    )
                });
            }}
        />
        <div className={styles.ratingContainer_buttons}>
            <div className={styles.ratingContainer_buttons_cancel} onClick={() => {
                close();
                setCurrentRatings(project?.ratingsSettings);
            }}>Cancel</div>
            <div className={`
            ${styles.ratingContainer_buttons_submit} 
            ${currentRatings.ratings[0].date === null
                    ? styles.ratingContainer_buttons_submit_disabled : ''}`} onClick={() => {
                        if (currentRatings.ratings[0].date === null) return
                        handleSubmit();
                        setIsSubmitted(true);
                        setTimeout(() => {
                            close();
                            setIsSubmitted(undefined)
                        }, 10000);
                    }}>Submit review</div>
        </div>

    </>

    return (
        <>
            {width > 768 &&
                ReactDOM.createPortal(
                    <div className={styles.ratingContainer} onClick={
                        (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                        }}>
                        <div className={styles.ratingContainer_container}>
                            <div className={styles.ratingContainer_container_closeButton}>
                                <img

                                    onClick={() => {

                                        close();
                                        if (isSubmitted === true) setIsSubmitted(undefined);
                                    }}
                                    src={CloseCalendar}
                                    alt="Close"
                                />

                            </div>
                            {isSubmitted === undefined && content}
                            {
                                isSubmitted === true && <div className={styles.ratingContainer_final}>
                                    <img className={styles.ratingContainer_final_foto} src={SuccessGap} alt="" />
                                    <div className={styles.ratingContainer_final_header} >Thank you!</div>
                                    <div className={styles.ratingContainer_final_text} >Your review has been successfully submitted!</div>
                                    <div className={styles.ratingContainer_final_title} >Your feedback means a lot to us! Thank you for taking the time to share your thoughts and help us improve.</div>
                                    <div className={styles.ratingContainer_final_buttons} >
                                        <div className={styles.ratingContainer_final_buttons_close} onClick={() => { close(); setIsSubmitted(undefined); }}  >Got it, close</div>
                                        <div className={styles.ratingContainer_final_buttons_review} onClick={() => { handleCheckReview(); }} >See my review</div>
                                    </div>

                                    {isSubmitted && <Realistic className={styles.ratingContainer_final_confetti} autorun={{ speed: 0.3, duration: 5000 }} />}
                                </div>
                            }
                        </div>
                    </div>,
                    document.body,
                )
            }
            {width < 768 && (
                <Sheet
                    isOpen={true}
                    onClose={close}
                    dragVelocityThreshold={500}
                    detent="full-height"
                    style={{
                        backdropFilter: "blur(3px)",
                        WebkitBackdropFilter: "blur(3px)",
                        background: "#11100E99",
                    }}
                    className={styles.learnMore_sheetMain}
                    onClick={
                        (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                        }}
                >
                    <div
                        className={styles.learnMore_closeArea}
                        onClick={close}

                    ></div>
                    <Sheet.Container className={styles.learnMore_sheet}>
                        <Sheet.Content className={styles.learnMore_sheetContainer}>
                            <div className={styles.learnMore_container_lineBox}>
                                <div className={styles.learnMore_container_line}></div>
                            </div>

                            <Sheet.Scroller draggableAt="both">
                                {isSubmitted === undefined && content}
                                {
                                    isSubmitted === true && <div className={styles.ratingContainer_final}>
                                        <img className={styles.ratingContainer_final_foto} src={SuccessGap} alt="" />
                                        <div className={styles.ratingContainer_final_header} >Thank you!</div>
                                        <div className={styles.ratingContainer_final_text} >Your review has been successfully submitted!</div>
                                        <div className={styles.ratingContainer_final_title} >Your feedback means a lot to us! Thank you for taking the time to share your thoughts and help us improve.</div>
                                        <div className={styles.ratingContainer_final_buttons} >
                                            <div className={styles.ratingContainer_final_buttons_close} onClick={() => { close(); setIsSubmitted(undefined); }}  >Got it, close</div>
                                            <div className={styles.ratingContainer_final_buttons_review} onClick={() => { handleCheckReview(); }} >See my review</div>
                                        </div>

                                        {isSubmitted && <Realistic className={styles.ratingContainer_final_confetti} autorun={{ speed: 0.3, duration: 5000 }} />}
                                    </div>
                                }
                            </Sheet.Scroller>

                        </Sheet.Content>
                    </Sheet.Container>
                </Sheet>
            )}
        </>
    )
}

export default ProjectRating;