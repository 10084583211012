export const answer = `Lorem ispum dollar is a dummy text that is used in place of
a text as a place holderLorem ispum dollar is a dummy text that is used
in place of a text as a place holderLorem ispum dollar is a dummy text that 
is used in place of a text as a place holderLorem ispum dollar is a `;

export const data = [
  {
    question: "What is LimeLite and how does it work?",
    answer,
  },
  {
    question: "Why do I need LimeLite?",
    answer,
  },
  {
    question: "Do I have to sign a long-term contract?",
    answer,
  },
  {
    question: "What is the process to request a new video?",
    answer,
  },
  {
    question: "What is the typical turnaround time?",
    answer,
  },
  {
    question: "What if I need additional services?",
    answer,
  },
  {
    question: "What if I need additional services?",
    answer,
  },
  {
    question: "What if I need additional services?",
    answer,
  },
];
