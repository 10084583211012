import {
  ADD_ONS,
  FAQS,
  INSPIRATIONS,
  LEARN,
  NEW_REQUEST,
  NOTIFICATIONS,
  PROJECTS,
  SETTINGS,
  SUPPORT,
  VIDEOS,
  WELCOME,
} from "consts/consts";
import useWindowWidth from "hooks/useWindowWidth";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { TSelectedPages } from "types/types";

import SideBarNavItem from "./components/SideBarNavItem";
import styles from "./SideBar.module.scss";
import {
  AddOns,
  Home,
  Inspiration,
  Learn,
  NewRequest,
  Notifications,
  Projects,
  Settings2,
  Support,
} from "../../assets/images";

interface IProps {
  isOpened: boolean;
  setIsOpened: (value: boolean) => void;
};
const SideBar = ({ isOpened, setIsOpened }: IProps) => {
  const [selectedPage, setSelectedPage] = useState<TSelectedPages>(WELCOME);
  const location = useLocation();
  const windowWidth = useWindowWidth();
  const PAGE_MAP: Record<string, TSelectedPages> = {
    learn: LEARN,
    welcome: WELCOME,
    notifications: NOTIFICATIONS,
    settings: SETTINGS,
    "new-request/start": NEW_REQUEST,
    "add-ons": ADD_ONS,
    inspirations: INSPIRATIONS,
    projects: PROJECTS,
    support: SUPPORT,
    faqs: FAQS,
    'chatSupport': SUPPORT,
    'videos': VIDEOS
  };

  useEffect(() => {
    const matchedPage = Object.entries(PAGE_MAP).find(([key]) =>
      location.pathname.includes(key)
    );

    if (matchedPage) {
      setSelectedPage(matchedPage[1]);
    }
  }, [location])

  const handleLinkClick = () => {
    windowWidth < 768 && setIsOpened(false);
  };
  const handleToggleIsOpened = () => {
    setIsOpened(!isOpened);
  }
  return (
    <div
      className={`${styles.sideBar} ${isOpened ? styles.sideBar_opened : ""}`}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={styles.sideBar_navItems}>
        <div className={styles.sideBar_navItemContainer}>
          <Link to="/welcome">
            <SideBarNavItem
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
              isOpened={isOpened}
              icon={`${Home}#Home`}
              value={WELCOME}
              onClick={handleLinkClick}
            />
          </Link>

          <div
            className={`${styles.sideBar_navItemContainer_switchArrow} ${isOpened ? styles.sideBar_navItemContainer_switchArrow_opened : ""
              }`}
            onClick={handleToggleIsOpened}
          />
        </div>
        <Link to="/new-request/start">
          <SideBarNavItem
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            isOpened={isOpened}
            icon={`${NewRequest}#NewRequest`}
            value={NEW_REQUEST}
            onClick={handleLinkClick}
          />
        </Link>
        <Link to="/projects">
          <SideBarNavItem
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            isOpened={isOpened}
            icon={`${Projects}#Projects`}
            value={PROJECTS}
            onClick={handleLinkClick}
          />
        </Link>
        <Link to="/learn" >
          <SideBarNavItem
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            isOpened={isOpened}
            icon={`${Learn}#Learn`}
            value={LEARN}
            onClick={handleLinkClick}
          />
        </Link>
        <Link to="/add-ons">
          <SideBarNavItem
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            isOpened={isOpened}
            icon={`${AddOns}#AddOns`}
            value={ADD_ONS}
            onClick={handleLinkClick}
          />
        </Link>
        <Link to="/inspirations">
          <SideBarNavItem
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            isOpened={isOpened}
            icon={`${Inspiration}#Inspiration`}
            value={INSPIRATIONS}
            onClick={handleLinkClick}
          />
        </Link>
        <div className={styles.sideBar_divider}></div>

        <Link to="/notifications" >
          <SideBarNavItem
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            isOpened={isOpened}
            icon={`${Notifications}#Notifications`}
            value={NOTIFICATIONS}
            onClick={handleLinkClick}
          />
        </Link>
        <Link to="/settings" >
          <SideBarNavItem
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            isOpened={isOpened}
            icon={`${Settings2}#Settings`}
            value={SETTINGS}
            onClick={handleLinkClick}
          />
        </Link>
        <Link to="/faqs" >
          <SideBarNavItem
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            isOpened={isOpened}
            icon={`${Learn}#Learn`}
            value={FAQS}
            onClick={handleLinkClick}
          />
        </Link>
        <Link to="/support">
          <SideBarNavItem
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            isOpened={isOpened}
            icon={`${Support}#Support`}
            value={SUPPORT}
            onClick={handleLinkClick}
          />
        </Link>
      </div>
    </div>
  );
};

export default SideBar;
