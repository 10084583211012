import styles from "./ProjectOverview.module.scss";
import { IRatingsSettings } from "interfaces/interfaces";
import { Rating } from "react-simple-star-rating";
import { EmptyStar, FilledStar } from "assets/images";
import useWindowWidth from "hooks/useWindowWidth";
interface IProps {
    ratingsSettings: IRatingsSettings
    handleLeaveReview: () => void
}
const RatingSettings = ({ ratingsSettings, handleLeaveReview }: IProps) => {
    const width = useWindowWidth();
    const total =
        (ratingsSettings.ratings[0].question1 +
            ratingsSettings.ratings[0].question2 +
            ratingsSettings.ratings[0].question3 +
            ratingsSettings.ratings[0].question4 +
            ratingsSettings.ratings[0].question5
        ) / 5;

    if (ratingsSettings.ratings[0].date === null) {
        return <>
            <div className={styles.infoContainer_feedbackContainer}>
                <div>You haven't left a review for this project yet. Share your feedback!</div>
                <div className={styles.infoContainer_feedbackContainer_button} onClick={handleLeaveReview}  >Leave a review</div>
            </div>
        </>
    }
    return (
        <>
            <div className={styles.infoContainer_rateHeader}>Reviews and ratings
                <div>
                    {total.toFixed(1)}
                    <Rating
                        emptyIcon={<img src={EmptyStar} alt={"empty star"} />}
                        fillIcon={<img src={FilledStar} alt={"empty star"} />}
                        transition
                        allowFraction
                        readonly
                        className={styles.rating}
                        initialValue={total}
                    />
                </div>
            </div>
            <div className={styles.infoContainer_title}><div className={styles.infoContainer_progressTitle}>Project Outcome Satisfaction: <div>{width < 768 && ratingsSettings.ratings[0].question1.toFixed(1)}</div></div>
                <div className={styles.infoContainer_progressContainer}>{width > 768 && ratingsSettings.ratings[0].question1.toFixed(1)}
                    <div className={styles.infoContainer_progress}>
                        <div className={styles.infoContainer_progressBar} style={{ width: `${Number(ratingsSettings.ratings[0].question1.toFixed(1)) * 20}%` }}></div>
                    </div>
                </div>
            </div>
            <div className={styles.infoContainer_title}><div className={styles.infoContainer_progressTitle}>Shooting Process Efficiency:<div>{width < 768 && ratingsSettings.ratings[0].question2.toFixed(1)}</div></div>
                <div className={styles.infoContainer_progressContainer}>{width > 768 && ratingsSettings.ratings[0].question2.toFixed(1)}
                    <div className={styles.infoContainer_progress}>
                        <div className={styles.infoContainer_progressBar} style={{ width: `${Number(ratingsSettings.ratings[0].question2.toFixed(1)) * 20}%` }}></div>
                    </div>
                </div>
            </div>
            <div className={styles.infoContainer_title}><div className={styles.infoContainer_progressTitle}>Communication & Collaboration:<div>{width < 768 && ratingsSettings.ratings[0].question3.toFixed(1)}</div></div>
                <div className={styles.infoContainer_progressContainer}>{width > 768 && ratingsSettings.ratings[0].question3.toFixed(1)}
                    <div className={styles.infoContainer_progress}>
                        <div className={styles.infoContainer_progressBar} style={{ width: `${Number(ratingsSettings.ratings[0].question3.toFixed(1)) * 20}%` }}></div>
                    </div>
                </div>
            </div>
            <div className={styles.infoContainer_title}><div className={styles.infoContainer_progressTitle}>Editing Alignment with Brand:<div>{width < 768 && ratingsSettings.ratings[0].question4.toFixed(1)}</div></div>
                <div className={styles.infoContainer_progressContainer}>{width > 768 && ratingsSettings.ratings[0].question4.toFixed(1)}
                    <div className={styles.infoContainer_progress}>
                        <div className={styles.infoContainer_progressBar} style={{ width: `${Number(ratingsSettings.ratings[0].question4.toFixed(1)) * 20}%` }}></div>
                    </div>
                </div>
            </div>
            <div className={styles.infoContainer_title}><div className={styles.infoContainer_progressTitle} >Recommendation Likelihood:<div>{width < 768 && ratingsSettings.ratings[0].question5.toFixed(1)}</div></div>
                <div className={styles.infoContainer_progressContainer}> {width > 768 && ratingsSettings.ratings[0].question5.toFixed(1)}
                    <div className={styles.infoContainer_progress}>
                        <div className={styles.infoContainer_progressBar} style={{ width: `${Number(ratingsSettings.ratings[0].question5.toFixed(1)) * 20}%` }}></div>
                    </div>
                </div>
            </div>
            {
                ratingsSettings.ratings[0].comment.length > 0 &&
                <>
                    <div className={styles.infoContainer_rateHeader}>Additional Comments
                    </div>
                    <div className={styles.infoContainer_comment}>
                        {ratingsSettings.ratings[0].comment}
                    </div>
                </>
            }
        </>
    )
}

export default RatingSettings;