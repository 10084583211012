import { ActorAddOn, AdditionalVideosAddOn, AfterHoursShotAddOn, AnimationAddOn, CandidPhotographyAddOn, CustomThumbnailAddOn, DroneAddOn, ForeignLanguageCaptionAddOn, ForeignLanguageVoiceoverAddOn, GoAddOn, HDEditingAddOn, HDShootAddOn, HomeRentalAddOn, IdeationSessionAddOn, InterviewQuestionAddOn, LimeliteVirtualInterviewAddOn, MakeUpAddOn, OnSetInterviewerAddOn, ProductionAssistantAddOn, RawDeliveryAddOn, RushEditAddOn, RushSchedulingAddOn, ScriptAddOn, ScriptWritingAddOn, SecondCameraAddOn, SocialEditAddOn, StudioRentalAddOn, VirtualInterviewAddOn, VoiceoverAddOn } from "assets/images";
import styles from "./AddOns.module.scss";
import { generateUniqueId } from "utils/generateId";
import AddOnItem from "./AddOnItem";
import { useState } from "react";
import useWindowWidth from "hooks/useWindowWidth";

const list = [
    {
        id: generateUniqueId(),
        header: 'Additional Videos',
        teaser: 'Add more videos to your LimeLite subscription to increase reach and boost engagement',
        text: `Additional videos can be added to your LimeLite subscription as needed. If you can't wait
                  for your monthly credits to replinish, this add-on gives you the flexibility to create additional 
                  content in any given month without upgrading your entire plan. With more videos, you can strengthen 
                  your digital presence which helps increase reach and boost engagement. Price includes an additional 
                  video shoot (≤ 3 hours) with a LimeLite videographer, and one additional editing session (≤ 1 day) 
                  per video.`,
        img: AdditionalVideosAddOn,
        premium: false,
        value: [2990],
        price: '+$2990'
    },
    {
        id: generateUniqueId(),
        header: 'After Hours Shoot',
        teaser: 'Scheduling that allows you to book your shoot on the day and time that works best for you',
        text: `A LimeLite video shoot scheduled before 8:00 am, after 4:00pm, or on a weekend is considered 
               an after-hours shoot. Sometimes you need to capture footage of events or operations that take 
               place outside of standard business hours. An after-hours shoot offers greater control and 
               flexibility by allowing you to book your shoot on a day and time that fits your schedule. Price 
               includes overtime expenses for your LimeLite Creator for each scheduled after-hours video shoot 
               (≤ 3 hours).`,
        img: AfterHoursShotAddOn,
        premium: false,
        value: [245],
        price: '+$245'
    },
    {
        id: generateUniqueId(),
        header: 'Candid Photography',
        teaser: 'Capture natural moments that show the true nature of your company, people, and culture',
        text: `Candid photography is a great way to capture authentic and 
                candid moments that convey emotions that resonate with your audience. Candid photography 
                involves taking spontaneous or unposed shots, allowing subjects to express genuine reactions and 
                feelings. This style is ideal for documenting events, culture, products, and services. It creates a 
                narrative that highlights the natural flow of your organization, making stories relatable and 
                memorable. Candid photography can also enhance marketing materials and showcase the true essence 
                of a brand by fostering a deeper connection with potential customers through authentic visual 
                storytelling. Price includes a photo shoot (≤ 3 hours) with a LimeLite Photographer and basic 
                color correction services. Advanced photo editing/Photoshop work is not included.`,
        img: CandidPhotographyAddOn,
        premium: false,
        value: [1495],
        price: '+$1495'
    },
    {
        id: generateUniqueId(),
        header: 'Drone Videography',
        teaser: 'Elevate your brand and give your audience a fresh, dynamic view with drone video',
        text: `Drone Videography is a great way to elevate a LimeLite Video campaign. Drone footage offers 
        dramatic aerial views that can make a powerful impression on your audience. The aerial footage will be 
        stored in your library of video assets and can be used to enhance any/all of your LimeLite Videos. Price 
        includes an additional Drone Shoot (≤ 90 minutes) with a LimeLite FAA Certified Drone Pilot.`,
        img: DroneAddOn,
        premium: false,
        value: [1495],
        price: '+$1495'
    },
    {
        id: generateUniqueId(),
        header: 'HD Editing Session',
        teaser: 'Add an HD Editing Session for projects with complex or out-of-scope editing requirements',
        text: `An additional HD editing session is the perfect solution for complex projects that require more 
        extensive editing than a traditional LimeLite video. Whether it's a multi-camera interview that requires 
        more complex synchronization, advanced graphics or visual effects, or other unique editing needs, this 
        service ensures that your video gets the attention it deserves. Price includes an additional half or full 
        day of non-linear editing. Please note that an additional HD editing session does not include motion graphic 
        animation design.`,
        img: HDEditingAddOn,
        premium: false,
        value: [750, 1495],
        price: '+$750 - $1,495'
    },
    {
        id: generateUniqueId(),
        header: 'HD Shoot',
        teaser: 'Add an HD Shoot for projects with complex or out-of-scope filming requirements',
        text: `An additional HD shoot is an ideal solution for complex video projects or those with extenuating 
        circumstances. Examples include multiple filming locations, separate interview sessions, offsite b-roll 
        shoots, or other circumstances that fall outside the scope of a LimeLite video shoot.  Adding an additional 
        HD shoot ensures flexibility, allowing you to capture all the necessary footage for your project without 
        any compromise on quality or time. Price includes an additional video shoot (≤ 3 hours) with a LimeLite 
        Creator and all standard LimeLite gear.`,
        img: HDShootAddOn,
        premium: false,
        value: [1495],
        price: '+$1495'
    },
    {
        id: generateUniqueId(),
        header: 'Home Rental',
        teaser: 'Put a personal touch on your video with a warm, relatable, and private shoot location',
        text: `Home rentals are a unique, unobtrusive shoot location. They provide a warm natural setting 
        that viewers relate to, and also offer privacy to put your on-camera subjects at ease. Home rentals 
        make excellent shoot locations for testimonials, interview-driven videos, product and service videos, and 
        more. LimeLite will present several home rental options for you to choose from, and we will manage all of 
        the details from scouting, booking, and deposits, to contracts, insurance and post-shoot cleanup. Price 
        includes a one-day home rental for your LimeLite video shoot.`,
        img: HomeRentalAddOn,
        premium: true,
        value: [695],
        price: '+$695'
    },
    {
        id: generateUniqueId(),
        header: 'Ideation Session',
        teaser: 'Start your next LimeLite video with a clear vision and strong creative foundation',
        text: `Need a creative idea for your next video? Let our video marketing gurus create two fully-developed 
        concepts with visual and messaging suggestions to supercharge your next LimeLite video! An ideation 
        session ensures your video begins with a clear vision and strong creative foundation. It’s perfect if 
        you’re struggling to come up with a strong video concept, have a general idea but need creative direction, 
        or want multiple options before committing to a single concept. Price includes a discovery call, concept 
        development, and delivery of two creative video concepts.`,
        img: IdeationSessionAddOn,
        premium: false,
        value: [495],
        price: '+$495'
    },
    {
        id: generateUniqueId(),
        header: 'LimeLite Go',
        teaser: 'Go wherever your story takes you—Shoot anywhere in the continental U.S. for a flat rate',
        text: `LimeLite GO℠ gives subscribers the freedom to schedule a LimeLite video shoot anywhere in 
        the continental U.S. for a simple, flat-rate surcharge. Zones are based on proximity to your local 
        LimeLite studio. LimeLite GO℠ ensures a seamless production experience. Wherever your story takes you, 
        we’ll be there to capture it with the same high-quality production you’ve come to rely on. Price includes 
        all travel-related expenses, including airfare, lodging, car rental, meals, and fuel.`,
        timeZones: [
            {
                header: 'Zone 1 (≤ 50 Miles)',
                title1: 'Covers:',
                text1: 'Greater Cincinnati area, Northern Kentucky, parts of Southeast Indiana',
                title2: 'Use Case',
                text2: 'Within our immediate service area, no travel necessary $0'
            },
            {
                header: 'Zone 2 (50 - 149 Miles)',
                title1: 'Covers:',
                text1: 'Columbus, Dayton, Louisville, Lexington, Indianapolis, etc',
                title2: 'Use Case',
                text2: 'Quick trips with minimal travel $295'
            },
            {
                header: 'Zone 3 (150 - 299 Miles)',
                title1: 'Covers:',
                text1: 'Cleveland, Pittsburgh, Detroit, Nashville, etc.',
                title2: 'Use Case',
                text2: 'Same-day trips where driving is preferred $895'
            },
            {
                header: 'Zone 4 (300 - 599 Miles)',
                title1: 'Covers:',
                text1: 'Chicago, Atlanta, St. Louis, Raleigh, etc.',
                title2: 'Use Case',
                text2: 'More complex travel that may include same day flights $1,495'
            },
            {
                header: 'Zone 5 (600 + Miles)',
                title1: 'Covers:',
                text1: 'East Coast, West Coast, Southern states, Mountain regions, etc.',
                title2: 'Use Case',
                text2: 'Full travel planning that often includes flights, overnight stays, and car rentals $1,695'
            },


        ],
        note: `Note: An advance notice of ten (10) business days is required to avoid additional fees. 
        Failure to provide advance notice may result in added fees.`,
        img: GoAddOn,
        premium: true,
        value: [295, 1495],
        price: '+$295-1,495'
    },
    {
        id: generateUniqueId(),
        header: 'Motion Graphic Accents',
        teaser: 'Add continuity, interest, and professionalism to your static logo with logo animation',
        text: `Motion graphics accents are available as an add-on service to enhance your video with animated 
        elements like text, logos, lower thirds, and transitions. These subtle elements help create more engaging, 
        visually dynamic content without overwhelming your core message. While we don’t produce full motion 
        graphic videos, our animated elements can add a professional touch that keeps viewers interested and 
        focused. Whether it’s a clean title animation or a stylish logo reveal, motion graphics bring your 
        project to life with just the right amount of flair—perfect for boosting visual impact when you need a 
        little extra polish. Price includes a half day of motion graphic design. `,
        img: AnimationAddOn, ///// change to MotionGraphicsAddOn
        premium: true,
        value: [895],
        price: '+$895'
    },
    {
        id: generateUniqueId(),
        header: 'Make-up Artist',
        teaser: 'Make sure your on-camera talent looks their best with a professional makeup artist',
        text: `A professional make-up artist can boost the level of professionalism of your LimeLite shoot while 
        ensuring that on camera talent—such as executives, employees, and speakers—look polished, professional, 
        and camera-ready. Their job goes beyond traditional makeup application, from simple shine and flyaway 
        control to correcting imperfections. Their primary focus is help individuals look their best under 
        professional lighting and high-definition cameras. Adding a Make-up Artist can boost the level of
         professionalism for any LimeLite shoot. Price includes the addition of a Make-up Artist on a shoot 
         (≤ 3 hours) with a professional make-up kit.`,
        img: MakeUpAddOn,
        premium: true,
        value: [600],
        price: '+$600'
    },
    {
        id: generateUniqueId(),
        header: 'Professional Actor',
        teaser: 'Add skilled talent to effectively communicate your message and connect to your audience',
        text: `Professional Actors are comfortable in front of the camera, and can connect with your audiences 
        in unique and meaningful ways. Whether you need a brand ambassador to introduce a new product, or a 
        personality for a branded web series, a Professional Actor is a great way to engage an audience and 
        boost sales. Price includes casting and auditions of local paid talent to perform on camera (scripted 
            or unscripted) during an HD Shoot (≤ 3 hours), and all usage rights 
            (for web/social media usage only).`,
        img: ActorAddOn,
        premium: true,
        value: [1695],
        price: '+$1,695'
    },
    {
        id: generateUniqueId(),
        header: 'Raw Footage Delivery',
        teaser: 'Get the original unedited and uncolored footage captured during your recent LimeLite shoot',
        text: `Raw files are the original unedited footage captured during your LimeLite video shoot. 
        These are high quality professional video files with no adjustments to color, sound, or visual effects. 
        Price includes all labor expenses (up to four hours), data transfer fees, a physical storage device, and 
        all shipping expenses. Deliverables include a physical drive containing all raw footage captured during 
        the specified/requested LimeLite project delivered within 5-7 business days.
        `,
        addInfo: [
            {
                header: 'Recent Projects',
                text: `For projects that have not been archived 
                (i.e., completed within the past 30 days), price includes up to
                 two hours of labor/data transfer, a physical storage device, and all 
                 shipping expenses. Deliverables include a physical drive containing all raw footage 
                 captured during the specified/requested LimeLite project delivered within 5-7 business days.  245`
            },
            {
                header: 'Archived Projects',
                text: `For projects that have been archived (i.e., completed over 30 days ago), price includes up 
                to four hours of labor/data transfer, a physical storage device, and all shipping expenses. 
                Deliverables include a physical drive containing all raw footage captured during the 
                specified/requested LimeLite project delivered within 5-7 business days.
                495`
            }
        ],
        note: `Note: Projects completed more than 30 days ago include additional labor fees to retrieve content 
        from archival storage. NLE source files are not included in the deliverables.`,
        img: RawDeliveryAddOn,
        premium: false,
        value: [245, 495],
        price: '+$245-495'
    },
    {
        id: generateUniqueId(),
        header: 'Rush Edit',
        teaser: 'With a LimeLite rush edit, your project moves to the front of the editing queue',
        text: `With a LimeLite rush edit, your project moves to the front of the editing queue. One of the 
        biggest perks of a LimeLite subscription is our turnaround times, but sometimes even lightning fast 
        isn’t fast enough. Whether a new project unexpectedly popped up, an existing deadline quickly crept 
        up, or you want to recap an event while it’s still fresh, we’ve got you covered. With rush editing, 
        your video is prioritized for rush delivery. 
        `,
        addInfo: [
            {
                header: '4-6 Days',
                text: `These fees allow us to prioritize your project, ensuring that your 
                first draft is delivered within 4-6 business days.
                $295`
            },
            {
                header: '2-3 Days',
                text: `These fees allow us to prioritize your project, ensuring that your first 
                draft is delivered within 2-3 business days.
                $495`
            },
            {
                header: 'Next Day Gauranteed',
                text: `These fees allow us to prioritize your project, ensuring that your first 
                draft is delivered within one business day.
                $895`
            }
        ],
        note: `Note: Projects completed more than 30 days ago include additional labor fees to retrieve 
        content from archival storage. NLE source files are not included in the deliverables.`,
        img: RushEditAddOn,
        value: [295, 495, 895],
        premium: false,
        price: '$295 - $895'
    },
    {
        id: generateUniqueId(),
        header: 'Scriptwriting',
        teaser: 'A script is the blueprint for your video—outlining narration, messaging, and visuals',
        text: `A video script is the blueprint for your video—it outlines dialogue and/or 
        narration, communicates key messages, and sets direction for the visuals, pacing, and tone.
        Unlike other forms of writing, scripts are meant to be read aloud. LimeLite Scriptwriters 
        specialize in writing concise, conversational, and persuasive scripts that match your style and 
        tone. Whether you’re promoting a product, explaining a service, or telling your brand story, we craft 
        on-brand scripts that resonate with your target audience. Price includes up to three telephone interviews 
        with key stakeholders or SME’s, scriptwriting, and three rounds of revision. `,
        img: ScriptWritingAddOn,
        premium: false,
        value: [895],
        price: '+$895'
    },
    {
        id: generateUniqueId(),
        header: 'Second Camera',
        teaser: 'Capture subject(s) from multiple angles simultaneously for a more visually engaging video',
        text: `A two-camera setup is the use of two cameras to capture the same subject(s) from multiple angles 
        simultaneously. Adding a second camera to your LimeLite shoot creates a more dynamic and visually engaging 
        video with a cinematic quality. Alternating angles can help control pacing and enhance storytelling by 
        allowing editors to seamlessly cut between different parts of a conversation. A great option for interviews, 
        panels, and events, adding a second camera can enhance branding and professionalism. Price includes a 
        second camera/angle. Second camera operator not included.`,
        img: SecondCameraAddOn,
        premium: false,
        value: [395],
        price: '+$395'
    },
    {
        id: generateUniqueId(),
        header: 'Studio Rental',
        teaser: 'A studio shoot offers a professional, controlled environment to film your LimeLite video.',
        text: `A studio shoot offers a professional, controlled environment to film your LimeLite video. Not only 
        are you able to limit foot traffic and potential disruptions, but you can better manage light and sound as 
        well. Plus, on-camera subjects may feel more comfortable and confident in a studio, free from workplace 
        distractions or interruptions. Studio shoots are excellent for demo videos, product and service videos, 
        explainers, interview driven videos, amd more. Price includes booking and all studio usage fees duing a 
        LimeLite shoot (≤ 3 hours).`,
        img: StudioRentalAddOn,
        premium: true,
        value: [695],
        price: '+$695'
    },
    {
        id: generateUniqueId(),
        header: 'Voiceover Artist',
        teaser: 'A professional voice artist can grab attention, amplify messaging and elevate content.',
        text: `A voiceover artist is a trained professional with expertise in delivering scripts with the tone, 
        pacing, energy, and inflection for your media. They elevate your content, amplify your message, and add 
        polish to your audio or video project. Adding a voiceover artist is an ideal solution for explainer 
        videos, training videos, TV commercials, social ads, radio spots, or any other media requiring narration. 
        LimeLite will manage talent auditions and present shortlisted candidates for your review and selection. 
        Price includes casting, booking, recording, and limited usage rights.`,
        img: VoiceoverAddOn,
        premium: false,
        value: [595],
        price: '+$595'
    },
    {
        id: generateUniqueId(),
        header: 'Custom Thumbnail Design',
        teaser: 'Grab attention and generate clicks with eye-catching images custom-designed for your video',
        text: `Grab attention and generate clicks with a unique, eye-catching thumbnail custom-designed for 
        your video. Your thumbnail is your video’s first impression, and you want to make a great one! A carefully 
        selected still shot from the video does the trick, but it doesn’t capture attention in the same way that a 
        custom thumbnail can. A custom thumbnail lets your audience know what the video is about. It can include 
        graphic elements, text and/or branding aimed at enticing viewers to click. Price includes design and 
        delivery of custom thumbnail with 2-3 rounds of revisions.`,
        img: CustomThumbnailAddOn, // placeholder
        premium: false,
        value: [495],
        price: '+$495'
    },
    {
        id: generateUniqueId(),
        header: 'Facilitated Virtual Interview',
        teaser: 'You lead an interview remotely via video conference on an internet-connected device',
        text: `A virtual interview is conducted remotely via video conference on an internet-connected 
        tablet/device while a LimeLite creator captures high-quality footage of your interview subjects. 
        With facilitated virtual interviews, you can interview from anywhere in the world. On location, your 
        LimeLite Creator will mount a tablet where you would ordinarily sit, then facilitate a video conference 
        between you and your interviewee. This innovative approach to on-camera interviews gives you the ultimate 
        flexibility when planning an interview-driven video. Price includes facilitated interview(s) for a 
        LimeLite shoot (≤ 3 hours), telephone pre-interviews, and associated technology and software costs.
        `,
        note: `Note: Virtual interviews offer significant cost and time savings by eliminating the need for your 
        interviewer to travel to the shoot; however, its entirely dependent on the availability of a reliable, 
        high-speed internet connection at the shoot location. LimeLite Videos is not responsible for any delays, 
        disruptions, or diminished quality resulting from poor connectivity, limited bandwidth, or network 
        outages. We strongly recommend confirming internet reliability in advance to ensure a smooth and 
        successful virtual interview experience.`,
        img: VirtualInterviewAddOn, // placeholder
        premium: false,
        value: [395],
        price: '+$395'
    },
    {
        id: generateUniqueId(),
        header: 'Foreign Language Captions',
        teaser: 'Make your videos accessible to a global audience with captions in 100+ languages',
        text: `Make your videos accessible to a global audience with foreign language captions. We provide 
        accurate, high-quality translations that ensure your message resonates across different languages and 
        cultures. Whether for marketing, training, or corporate communication, our captions help expand your 
        reach, improve engagement, and enhance accessibility. Available as open captions (burned-in) or closed 
        captions (toggleable), our service ensures clarity and accuracy, making your content more inclusive and 
        impactful worldwide. Price includes content translation (≤ 3 minutes) captions, and your choice of 
        burned-in captions or separate SRT files.
        `,
        note: `Note: We strongly recommend that a subject matter expert from your team review all translated 
        content for accuracy and context. In particular, LimeLite Videos is not responsible for errors or 
        misinterpretations resulting from highly technical or industry-specific language.`,
        img: ForeignLanguageCaptionAddOn, // placeholder
        premium: true,
        value: [595],
        price: '+$595'
    },
    {
        id: generateUniqueId(),
        header: 'Foreign Language Voiceover',
        teaser: 'Grab attention and generate clicks with eye-catching images custom-designed for your video',
        text: `Expand your video’s reach with foreign language voiceovers. We provide high-quality, 
        professional voiceover services in more than 100 languages, ensuring your content connects with 
        international audiences. Our native-speaking voice talent delivers accurate translations while 
        maintaining the tone and message of your original video. This helps you engage diverse viewers, 
        increase conversion rates, and build a stronger global presence. With our foreign language voiceovers, 
        your video content will resonate with audiences worldwide, making it more impactful and effective. 
        Price includes casting, auditions, recording session (≤ 3 minutes of content), and unlimited usage 
        rights.
        `,
        note: `Note: We strongly recommend that a subject matter expert from your team review all translated 
        content for accuracy and context. In particular, LimeLite Videos is not responsible for errors or 
        misinterpretations resulting from highly technical or industry-specific language. `,
        img: ForeignLanguageVoiceoverAddOn, // placeholder
        premium: false,
        value: [795],
        price: '+$795'
    }
    ,
    {
        id: generateUniqueId(),
        header: 'Interview Question Development',
        teaser: 'Strategic questions prompt compelling responses—that’s what memorable videos are made of',
        text: `Strategic interview questions prompt compelling responses, and that’s what 
        memorable videos are made of. With interview question development, a LimeLite Content Strategist 
        will align with you on the intended story arc, then reverse engineer questions and talking points to 
        capture the story through interview(s). Thoughtful, well-crafted questions also help the interviewee 
        open up and share valuable insights, ensuring the content is engaging and authentic. Price includes a 
        discovery call to align on messaging, pre-interview call(s) with interviewee(s), interview question 
        development, and talking point suggestions.
        `,
        img: InterviewQuestionAddOn, // placeholder
        premium: false,
        value: [495],
        price: '+$495'
    },
    {
        id: generateUniqueId(),
        header: 'LimeLite Virtual Interview',
        teaser: 'A pro leads an interview remotely via video conference on an internet-connected device',
        text: `A virtual interview is conducted remotely via video conference on an internet-connected 
        tablet/device while a LimeLite creator captures high-quality footage of your interview subjects. With 
        professional virtual interviews, you get the best of both worlds—a skilled LimeLite interviewer guiding 
        authentic, compelling responses, without the added cost or inconvenience of having additional crew members 
        on set. This innovative approach to on-camera interviews gives you the ultimate flexibility when 
        planning an interview-driven video. Price includes professional interview(s) for a LimeLite shoot 
        (≤ 3 hours), telephone pre-interviews, and associated technology and software costs.
        `,
        note: `Note: Virtual interviews offer significant cost and time savings by eliminating the need for a 
        professional interviewer to travel to the shoot; however, its entirely dependent on the availability of a 
        reliable, high-speed internet connection at the shoot location. LimeLite Videos is not responsible for 
        any delays, disruptions, or diminished quality resulting from poor connectivity, limited bandwidth, or 
        network outages. We strongly recommend confirming internet reliability in advance to ensure a smooth and 
        successful virtual interview experience.`,
        img: LimeliteVirtualInterviewAddOn, // placeholder
        premium: false,
        value: [495],
        price: '+$495'
    },
    {
        id: generateUniqueId(),
        header: 'On-Set Interviewer',
        teaser: 'A professional leads on-set interviews that bring out the best in your interviewees',
        text: `With a professional on-set interview, a skilled LimeLite interviewer leads high-quality, 
        engaging conversations that bring out the best in your interviewees. Our professional approach helps 
        interviewees feel comfortable, leading to natural storytelling and clear, impactful communication. 
        Ideal for a wide range of video types like testimonials, announcement videos, service videos, spotlights, 
        and more, on-set interviews led by a professional interviewer capture authentic, compelling responses that 
        align with your vision. Price includes a professional interview on set for a LimeLite shoot (≤ 3 hours) 
        and telephone pre-interviews with interviewees prior to the scheduled shoot. 
        `,
        note: `Note: If your shoot requires travel, you will inclur additional LimeLite Go expenses for the 
        interviewer to travel to your shoot. `,
        img: OnSetInterviewerAddOn, // placeholder
        premium: false,
        value: [595],
        price: '+$595'
    },
    {
        id: generateUniqueId(),
        header: 'Pre-Production Support',
        teaser: 'Get project-specific coordination and detailed planning to ensure your shoot runs smoothly',
        text: `With pre-production support, you get project-specific coordination and detailed planning 
        to ensure your shoot runs smoothly and the video edit captures your vision. Whether you have a 
        complex video concept with a lot of moving parts, or you simply need assistance managing the details 
        of your shoot, pre-production support allows you to approach your project with confidence. Price 
        includes a project kickoff call; management of assets, scope, timelines, resource allocation, and 
        schedule; project updates and premium support via telephone, email, and/or slack; production plans; and 
        direction for the editing team.  
        `,
        note: `Note: If your shoot requires travel, you will inclur additional LimeLite Go expenses for the 
        interviewer to travel to your shoot. `,
        img: ProductionAssistantAddOn,
        premium: false,
        value: [495],
        price: '+$495'
    },
    {
        id: generateUniqueId(),
        header: 'Production Assistant',
        teaser: 'A Production Assistant supports your LimeLite Creator on-set during your shoot',
        text: `A Production Assistant supports your LimeLite Creator on-set during your shoot. Whether 
        they’re setting up equipment, managing schedules, operating teleprompters, or assisting with talent, a 
        Production Assistant helps ensure everything runs smoothly on the day of your shoot. Adding a Production 
        Assistant is ideal for complex or out-of-scope shoots that require an extra set of hands, or run the 
        risk of going over the time limit of a standard LimeLite shoot (≤ 3 hours). Price includes an on-set 
        production assistant to help manage the technical and logistical elements of your shoot. 
        `,
        note: `Note: If your shoot requires travel, you will inclur additional LimeLite Go expenses for the 
        Production Assistant to travel to your shoot.`,
        img: ProductionAssistantAddOn, // placeholder
        premium: false,
        value: [495],
        price: '+$495'
    },
    {
        id: generateUniqueId(),
        header: 'Rush Scheduling',
        teaser: 'Rush Scheduling accommodates shoot requests with 5 days or less notice, even on weekends',
        text: `It's always best to schedule your LimeLite shoots at least five days in advance; however, we 
        realize that's not always possible. With rush scheduling, LimeLite accommodates shoot requests with 
        three days notice or less, even on weekends. We prioritize your shoot request and quickly adjust 
        resources to meet tight deadlines while maintaining our high production standards. Weekend rush 
        scheduling offers the ultimate flexibility, allowing you to schedule on LimeLite’s off days. Price 
        includes additional labor costs and scheduling accommodations for your LimeLite shoot (≤ 3 hours). 
        `,
        addInfo: [
            {
                header: 'Three Days Notice',
                text: `With 72 hours of notice, we'll shift gears quickly and make it happen! 
                $395`
            },
            {
                header: 'Two Days Notice',
                text: `This add-on will ensure that we prioritize your request, prep gear, and lock-in your creator. 
                $495`
            },
            {
                header: 'One Day Notice',
                text: `Need it now? With less than 24 hours' notice, we mobilize quickly to make your shoot happen.
                $595`
            }
        ],
        note: `Note: It's always best to schedule your LimeLite shoots at least five days in advance`,
        img: RushSchedulingAddOn, // placeholder
        premium: false,
        value: [395, 495, 595],
        price: '$395 - $595'
    },
    {
        id: generateUniqueId(),
        header: 'Script Editing',
        teaser: 'Refine your script to effectively communicate your message and engage the audience. ',
        text: `
        Social edits allow you to leverage existing video content to reach a wider, more engaged audience on 
        social media platforms (with durations optimized for social).These edits are tailored to the fast-paced, 
        visual, and mobile-friendly nature of social media.
        `,
        addInfo: [
            {
                header: 'Standard: 16:9',
                text: `16:9 is the default video format. While 16:9 is great for television and most social 
                media posts, it doesn’t work well for social stories.  
                $75s`
            },
            {
                header: 'Story: 9:16',
                text: `9:16 is the most common aspect ratio for vertical videos. This format is excellent for social stories which are sized for vertical viewing on mobile devices.
                $75`
            },
            {
                header: 'Square: 1:1',
                text: `1:1 Square Videos are the most universally supported aspect ratio—particularly on social media. This format is great for posts, but doesn’t work well for stories. 
                $75`
            },
            {
                header: 'Vertical: 4:5',
                text: `4:5 vertical videos are great for social posts, but are not well suited for stories. This format fits more into the frame vertically than the 1:1, but is less commonly used.  
                $75`
            }
        ],
        note: `Note: Price includes the social format of your choice with a duration optimized for social media. `,
        img: ScriptAddOn, // placeholder
        premium: false,
        value: [395, 495, 595],
        price: '$395 - $595'
    },

    {
        id: generateUniqueId(),
        header: 'Social Edit',
        teaser: 'Leverage existing video content to reach a wider, more engaged audience on social media.',
        text: `
        Social edits allow you to leverage existing video content to reach a wider, more engaged 
        audience on social media platforms (with durations optimized for social).These edits are 
        tailored to the fast-paced, visual, and mobile-friendly nature of social media.
        `,
        addInfo: [
            {
                header: 'Standard: 16:9',
                text: `16:9 is the default video format. While 16:9 is great for television and most social 
                media posts, it doesn’t work well for social stories.  
                $75s`
            },
            {
                header: 'Story: 9:16',
                text: `9:16 is the most common aspect ratio for vertical videos. This format is excellent for social stories which are sized for vertical viewing on mobile devices.
                $75`
            },
            {
                header: 'Square: 1:1',
                text: `1:1 Square Videos are the most universally supported aspect ratio—particularly on social media. This format is great for posts, but doesn’t work well for stories. 
                $75`
            },
            {
                header: 'Vertical: 4:5',
                text: `4:5 vertical videos are great for social posts, but are not well suited for stories. This format fits more into the frame vertically than the 1:1, but is less commonly used.  
                $75`
            }
        ],
        note: `Note: Price includes the social format of your choice with a duration optimized for social media. `,
        img: SocialEditAddOn, // placeholder
        premium: false,
        value: [75],
        price: '$75'
    },

]

const AddOnsPage = () => {
    const [filter, setFilter] = useState('All Options');
    const width = useWindowWidth();
    return (
        <div>
            <div className={styles.page_header}>Add-ons</div>
            <div className={styles.page_types}>
                <div className={`${styles.page_types_item} ${filter === 'All Options' ? styles.page_types_item_active : ''}`} onClick={() => setFilter('All Options')} >{width > 768 ? 'All Options' : 'All Add-ons'}</div>
                <div className={`${styles.page_types_item} ${filter === 'Premium Add-ons' ? styles.page_types_item_active : ''}`} onClick={() => setFilter('Premium Add-ons')}>{width > 768 ? 'Premium Add-ons' : 'Premium'}</div>
                <div className={`${styles.page_types_item} ${filter === 'Standard Add-ons' ? styles.page_types_item_active : ''}`} onClick={() => setFilter('Standard Add-ons')}>{width > 768 ? 'Standard Add-ons' : 'Standard'}</div>
            </div>
            <div className={styles.page_list}>
                {
                    list.filter(item => filter === 'All Options' ? true : item.premium === (filter === 'Premium Add-ons')).map((item) => {
                        return (
                            <AddOnItem key={item.id} item={item} />
                        )
                    })
                }
            </div>

        </div>
    )
}

export default AddOnsPage;