import { DirectionArrow, People, VideoExample1, VideoExample2, VideoExample3, VideoExample4, VideoExample5, VideoExample6, VideoExample7, WhatisNew1 } from "assets/images";
import styles from "./Videos.module.scss";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { generateUniqueId } from "utils/generateId";
import VideoItem from "./VideoItem";


const data = [
    {
        id: generateUniqueId(),
        img: VideoExample1,
        header: "Managing your first project",
        type: ["Onboarding"]
    },
    {
        id: generateUniqueId(),
        img: VideoExample2,
        header: "Optimizing your workflow",
        type: ["Production", "Pre-pro",]
    },
    {
        id: generateUniqueId(),
        img: VideoExample3,
        header: "What is a video?",
        type: ["Production", "Post"]
    },
    {
        id: generateUniqueId(),
        img: VideoExample4,
        header: "User Roles & Permissions",
        type: ["Onboarding", "Production", 'Pre-pro']
    },
    {
        id: generateUniqueId(),
        img: VideoExample6,
        header: "User Roles & Permissions",
        type: ["Onboarding", "Production"]
    },
    {
        id: generateUniqueId(),
        img: VideoExample7,
        header: "User Roles & Permissions",
        type: ["Onboarding", "Production"]
    },
    {
        id: generateUniqueId(),
        img: VideoExample5,
        header: "User Roles & Permissions",
        type: ["Onboarding", "Production"]
    },
    {
        id: generateUniqueId(),
        img: VideoExample6,
        header: "User Roles & Permissions",
        type: ["Onboarding", "Production"]
    },
    {
        id: generateUniqueId(),
        img: VideoExample7,
        header: "User Roles & Permissions",
        type: ["Onboarding", "Production"]
    },

    {
        id: generateUniqueId(),
        img: WhatisNew1,
        header: "What is a video?",
        type: ["Production", "Pre-pro",]
    },
    {
        id: generateUniqueId(),
        img: VideoExample2,
        header: "Optimizing your workflow",
        type: ["Production", "Pre-pro",]
    },
    {
        id: generateUniqueId(),
        img: VideoExample3,
        header: "What is a video?",
        type: ["Production", "Post"]
    },
    {
        id: generateUniqueId(),
        img: VideoExample2,
        header: "Optimizing your workflow",
        type: ["Production", "Pre-pro",]
    },
    {
        id: generateUniqueId(),
        img: VideoExample3,
        header: "What is a video?",
        type: ["Production", "Post"]
    },
    {
        id: generateUniqueId(),
        img: VideoExample2,
        header: "Optimizing your workflow",
        type: ["Production", "Pre-pro",]
    },
    {
        id: generateUniqueId(),
        img: VideoExample3,
        header: "What is a video?",
        type: ["Production", "Post", "Onboarding"]
    },
]
const Videos = () => {
    const [navigation, setNavigation] = useState('All');
    return <div className={styles.learn}>
        <div className={styles.learn_header}>Learn</div>
        <div className={styles.learn_title}>A magna ut pulvinar est vulputate nec enim imperdiet. Placerat cras.</div>
        <div className={styles.learn_navigation}>
            <div className={`${styles.learn_navigation_item} ${navigation === 'All' ? styles.learn_navigation_item_active : ''}`} onClick={() => setNavigation('All')}>All</div>
            <div className={`${styles.learn_navigation_item} ${navigation === 'Onboarding' ? styles.learn_navigation_item_active : ''}`} onClick={() => setNavigation('Onboarding')}>Onboarding</div>
            <div className={`${styles.learn_navigation_item} ${navigation === 'Pre-Pro' ? styles.learn_navigation_item_active : ''}`} onClick={() => setNavigation('Pre-Pro')}>Pre-Pro</div>
            <div className={`${styles.learn_navigation_item} ${navigation === 'Production' ? styles.learn_navigation_item_active : ''}`} onClick={() => setNavigation('Production')}>Production</div>
            <div className={`${styles.learn_navigation_item} ${navigation === 'Post' ? styles.learn_navigation_item_active : ''}`} onClick={() => setNavigation('Post')}>Post</div>
        </div>
        <div className={styles.learn_list}>
            {data.map((item, index) => {
                if (navigation !== 'All' && !item.type.includes(navigation)) return null
                return (
                    <VideoItem video={item} />
                )
            })}
        </div>
        <div className={styles.learn_contact} >
            <img src={People} alt="" />
            <div className={styles.learn_contact_info} >
                <div className={styles.learn_contact_info_title}>Still have questions?<br></br> We're here to help!</div>
                <Link to="/support" >
                    <div className={styles.learn_contact_info_text}> Chat with a specialist
                        <img src={DirectionArrow} alt={"DirectionArrow"} />
                    </div>
                </Link>
            </div>
        </div>

    </div>;
};
export default Videos;