import { useIsPathIncluded } from "utils/pathChecker";
import { useEffect } from "react";
import { useLocation } from "react-router";
declare global {
    interface Window {
        Chatra: any;
        ChatraSetup: any;
    }
}

const ChatraWidget = () => {
    const location = useLocation();
    const isNewRequestSteps = useIsPathIncluded([
        "new-request/start",
        "new-request/project",
        "new-request/logistics",
        "new-request/narration",
        "new-request/video-edit",
        "new-request/add-ons",
        "new-request/submit",
        "new-request/final",
    ]);
    const isChatPage = useIsPathIncluded(["chatSupport"]);

    useEffect(() => {

        return () => {
            if (window.Chatra) {
                window.Chatra('shutdown');
            }
        };
    }, []);


    useEffect(() => {
        if (!isNewRequestSteps) {
            if (window.Chatra) {
                window.Chatra('hide');
            }
            if (isChatPage) {
                window.Chatra('restart');
                window.Chatra('openChat');
                window.ChatraSetup = {
                    mode: 'frame',
                    injectTo: "chatra-container"
                };

            } else {
                window.ChatraSetup = {
                    mode: 'widget'
                };
                window.Chatra('hide');
            }
        } else {
            window.ChatraSetup = {
                mode: 'widget'
            };
            window.Chatra('restart');
        }
    }, [location]);


    return null;

};

export default ChatraWidget;
