import { useState } from "react";
import { useNavigate } from "react-router";

import styles from "./Auth.module.scss";

const Auth = () => {
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  //   useEffect(() => {
  //     if (localStorage.getItem("isLogged") === "true") {
  //       navigate("/welcome");
  //     }
  //   }, []);

  const handleLogin = () => {
    if (password === "lemonbold") {
      localStorage.setItem("isLogged", "true");
      navigate("/loader");
    } else {
      setPassword("");
    }
  };
  return (
    <div className={styles.auth}>
      <div className={styles.auth_title}>Enter a password</div>
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleLogin();
          }
        }}
      />
      <button
        className={styles.auth_button}
        onClick={handleLogin}
        type="button"

      >
        Enter
      </button>
    </div>
  );
};
export default Auth;
