import "swiper/css";
import "swiper/css/pagination";

import {
  CompanyType,
  EducationType,
  InspirationVideo1,
  InspirationVideo2,
  InspirationVideo3,
  InspirationVideo4,
  SocialMediaType,
} from "assets/images";
import useWindowWidth from "hooks/useWindowWidth";
import { useEffect, useState } from "react";
import {
  A11y,
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
  Scrollbar,
} from "swiper/modules";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { generateUniqueId } from "utils/generateId";

import styles from "./Inspirations.module.scss";
import VideoItem from "./VideoItem";

export interface IVideo {
  id: string;
  header: string;
  text: string;
  img: string;
  imgType: string;
}

const InspirationVideos = (): JSX.Element => {
  const [position, setPosition] = useState(0);
  const windowWidth = useWindowWidth();
  const value = windowWidth > 1024 ? 5 : windowWidth < 768 ? 7 : 6;
  const ChangeSlide = ({ position }: { position: number }) => {
    const swiper = useSwiper();
    useEffect(() => {
      if (swiper) {
        swiper.slideTo(position);
      }
    }, [swiper, position]);
    return null;
  };


  const items: IVideo[] = [
    {
      id: generateUniqueId(),
      header: "Social Media",
      text: "The Power of Innovation",
      img: InspirationVideo1,
      imgType: SocialMediaType,
    },
    {
      id: generateUniqueId(),
      header: "Company Overview",
      text: "From Concept to Reality: The creative process",
      img: InspirationVideo2,
      imgType: CompanyType,
    },
    {
      id: generateUniqueId(),
      header: "Social Media",
      text: "Storytelling that connects. Emotion in Motion",
      img: InspirationVideo3,
      imgType: SocialMediaType,
    },
    {
      id: generateUniqueId(),
      header: "Social Media",
      text: "Success in Action: Results that speak",
      img: InspirationVideo4,
      imgType: EducationType,
    },
    {
      id: generateUniqueId(),
      header: "Social Media",
      text: "The Power of Innovation",
      img: InspirationVideo1,
      imgType: EducationType,
    },
    {
      id: generateUniqueId(),
      header: "Social Media",
      text: "From Concept to Reality: The creative process",
      img: InspirationVideo2,
      imgType: EducationType,
    },
    {
      id: generateUniqueId(),
      header: "Social Media",
      text: "Storytelling that connects. Emotion in Motion",
      img: InspirationVideo3,
      imgType: SocialMediaType,
    },
    {
      id: generateUniqueId(),
      header: "Social Media",
      text: "Success in Action: Results that speak",
      img: InspirationVideo4,
      imgType: EducationType,
    },
  ];
  return (
    <div className={styles.videos}>
      <div className={styles.videos_text}>
        Featured Videos
      </div>
      <div
        className={styles.videos_cardsList}
      >
        <Swiper
          modules={[
            Navigation,
            Pagination,
            Scrollbar,
            A11y,
            Keyboard,
            Mousewheel,
          ]}
          allowTouchMove={windowWidth > 768 ? false : true}
          spaceBetween={16}
          draggable={false}
          scrollbar={{ draggable: false }}
          slidesPerView={windowWidth > 1440 ? 6 : 5}
          className={
            styles.videos_cardsList_swiper
          }
        >
          <ChangeSlide position={position} />
          {items.map((item) => {
            return (
              <SwiperSlide
                key={item.id}
              >
                <VideoItem video={item} />
              </SwiperSlide>
            );
          })}
          {Array.from({ length: windowWidth < 990 ? 4 : 3 }).map(() => (
            <SwiperSlide
              key={generateUniqueId()}
              className={
                styles.videos_cardItem
              }
            ></SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div
        className={
          styles.videos_cardsList_bullets
        }
      >
        <button
          className={
            styles.videos_cardsList_bullets_back
          }
          onClick={() => position > 0 && setPosition(position - 1)}
        ></button>
        <button
          onClick={() => position < value && setPosition(position + 1)}
          className={
            styles.videos_cardsList_bullets_forward
          }
        ></button>
      </div>
    </div>
  );
};

export default InspirationVideos;
